@import 'src/styles/constants';

.opdrachtWrapper {
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.opdrachtHeaderWrapper {
  display: flex;
  flex-direction: column;
  position: sticky;
  width: 100%;
  border-bottom: 1px solid $GRAY-1;
  box-shadow: $GRAY-1 0px 0px 9px -1px;
}

.pageMargin {
  margin: 14px;
}
