@import 'src/styles/constants';

.dashboardWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;
}

.dashboardHeader {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  gap: 8px;
  width: 100vw;
  overflow: hidden;
  flex-wrap: nowrap;

  @media (max-width: $breakpoint-large) {
    flex-wrap: wrap;
    padding: 8px 8px 24px 12px;
  }
  
  .dropdown-filter {
    .ms-Dropdown {
      min-Width: 200px;
      @media (max-width: $breakpoint-large) {
        min-width: calc(50vw - 119px);
      }
      @media (max-width: $breakpoint-medium) {
        min-width: calc(50vw - 14px);
      }
      
    }
  }
  
  .zoekterm {
    min-width: 200px;
    @media (max-width: $breakpoint-large) {
      min-width: calc(100% - 230px);
    }
    @media (max-width: $breakpoint-medium) {
      min-width: calc(100vw - 20px);
    }
   
    
  }
}