@import 'src/styles/constants';

.Collapse {
  background-color: transparent;
  border: none;
}

.icons {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $ms-color-white;
}
.todayAndTomorrow {
  background-color: $ms-color-sharedGreen10;
}


.late {
  background-color: $ms-color-sharedOrange20;
}

.later {
  background-color: $ms-color-sharedCyanBlue10;
}

.panelContainer {
  font-weight: bolder;
  box-shadow: $ms-depth-shadow-16;
  border-bottom: 1px solid #efefef;
  border-radius: 8px;
  padding: 10px;
  margin: 12px 8px;
  background-color: #f3f2f1;
}

.datePanelContainer {
  font-weight: 600;
}

.panelContainer > .ant-collapse-header {
  font-size: 16px;
  font-weight: 600;
}

.datePanelContainer > .ant-collapse-header {
  border-top: 2px solid white;
}

.jobsSection {
  background-color: #f7f7f7;
  overflow: auto;
  flex: 1 1 auto;

  @media (max-width: $breakpoint-small) {
    height: 80%;
  }
}

.pastFuturePanel > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}