@import "styles/constants.scss";
@import "styles/styles.scss";

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.main {
  display: flex;
  flex: 1;
  overflow: hidden;
  @media (max-width: $breakpoint-medium) {
    flex-direction: column;
  }
}

.navbar {
  display: flex;
  flex-direction: column;
  
  // if smaller than 768px, hide navbar
    @media (max-width: $breakpoint-medium) {
        display: none;
    }
}

.navbar-top {
  display: none;
  // if larger than 768px, show navbar
  //  @media (min-width: 768px) {
  //      display: flex;
  //  }
  @media (max-width: 768px) {
    display: flex;
  }
}

.navbar > div {
  flex: 1;
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
  //width: calc(100vw - 201px);
  //height: calc(100vh - 48px);
}
