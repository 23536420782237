@import '~@fluentui/react/dist/sass/References';
@import 'styles/constants';

.samenvatting {
  &-card {
    overflow: visible;
    padding-left: 24px;
    padding-top: 0;
  }

  &-soort-wrapper {
    display: flex;
    overflow: visible;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: 4px;
  }

  .onderdeel {
    font-size: $ms-font-size-14;
    font-weight: $ms-font-weight-semibold;
    padding-top: 12px;
  }
  
  .opdracht-regel {
    padding-bottom: 8px;
    
    &-kop {
        font-weight: $ms-font-weight-semibold;
        padding-top: 4px;
    }
  }

  .valuelabel {
    display: flex;
    flex-direction: row;

    .label {
      font-size: $ms-font-size-14;
      font-weight: $ms-font-weight-regular;
      max-width: 300px;
      width: 300px;
    }
  }
  

}