@import 'styles/constants';
@import '~@fluentui/react/dist/sass/References';

.toolbar {
  height: 48px;
  background: $TOOLBAR-BG;
  opacity: 1;
}

.toolbarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.toolbarLeft,
.toolbarCenter,
.toolbarRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.toolbarCenter {
  flex-grow: 1;
  flex-shrink: 2;
    justify-content: flex-start;
}

.toolbarSpacer {
  margin-left: 6.25px;
  margin-right: 6.25px;
}

.toolbarItem {
  border-radius: 0;
  height: 48px;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: semibold;
}

.toolbarText {
  font-size: 16px;
  color: $TEXT-COLOR-LIGHT;
  user-select: none;
}

.toolbarIcon {
  width: 48px;
  height: 48px;
  color: $TOOLBAR-ICON-COLOR;
}

.toolbarIcon:hover {
  background-color: $TOOLBAR-ICON-HOVER;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
}

.avatar {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.noAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 0.1em solid $TOOLBAR-ICON-COLOR;
}

.toolbar .aannemer-naam {
  font-size: $ms-font-size-20;
  font-weight: $ms-font-weight-semibold;
  color: $TEXT-COLOR-LIGHT;
  padding-left: 50px;
  @media (max-width: $breakpoint-medium) {
    padding-left: 8px;
  }
}

.toolbar .aannemer-glncode {
  font-size: $ms-font-size-10;
  font-weight: $ms-font-weight-regular;
  color: $TEXT-COLOR-LIGHT;
  padding-left: 24px;
  margin: auto 0;
  padding-top: 8px;
  @media (max-width: $breakpoint-medium) {
    display: none;
  }
}