
@import 'src/styles/constants';

.jobItemsContainer {
  cursor: pointer;
  background-color: $ms-color-white;
  border-radius: 3px;
  box-sizing: 'border-box';
  border: 1px solid #efefef;
  box-shadow: $ms-depth-shadow-4;
  margin: 10px;
  padding: 0 10px 10px 10px;

  @media (max-width: $breakpoint-small) {
    margin: 10px 0 0 0;
  }

.jobContainer {

  .innerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0;
  }

  .bolder {
    font-weight: 600;
  }

  .dates {
    text-align: right;

    .extra-spacing {
      margin-top: 10px;
    }
    
  }

  .nestedJobs {
    margin: 10px;
    padding: 16px;
  }

}

}

.jobItemsContainer:hover {
  box-shadow: $ms-depth-shadow-16;
}