@import 'src/styles/constants';

.dashboardWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.greenIcon {
  color: $GREEN-1;
}

.icon {
  color: $GRAY-4;
}

.dashboard {
  display: flex;
  height: 100%;
  width: 100%;
}

.dashboardHeader {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 8px;
  overflow: hidden;
  flex-wrap: nowrap;

  @media (max-width: $breakpoint-small) {
    flex-wrap: wrap;
    padding: 8px 8px 24px 12px;
  }
  
  .dropdown-filter {
    .ms-Dropdown {
      min-Width: 160px;
      max-Width: 300px;
      width: 30vw;

      @media (max-width: $breakpoint-small) {
        max-width: 160px;
      }
    }
  }
  
  .zoekterm {
    min-width: 160px;
    max-width: 300px;
    width: 30vw;
    @media (max-width: $breakpoint-small) {
      max-width: 160px;
    }
  }
}

.spinnerWrapper {
  margin-top: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
