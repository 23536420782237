@import "styles/constants.scss";
@import "styles/styles.scss";

.ms-CommandBar-secondaryCommand {
  @media (max-width: $breakpoint-medium) {
    display: none;
  }
}

.ms-CommandBar-primaryCommand {
    .onderhandenopdrachten-title {
      @media (max-width: $breakpoint-small) {
        display: none;
      }
    }
}

